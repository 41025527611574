import constant from '@utils/constant';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'gatsby';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import { convertToDate, convertToDateTime, getTimeZone } from '@utils/common';
import { Tooltip } from 'antd';
import { createCancelToken } from '@apis/ServiceController';
import RequestModel from '@apis/models/RequestModel';
import { eventService } from '@services/eventService';
import IconAdd2 from '@icons/IconAdd2';
import ContentLoading from '@views_admin/ContentLoading';
import Search from '@views_admin/layout/Search';
import CommonTable from '@views_admin/components/CommonTable';
import IconEdit from '@icons/IconEdit';
import Sort from '@views_admin/components/Sort';
import { SortType } from '@apis/enums/SortType';
import Filter from '@views_admin/events/Filter';
import { EventStatus } from '@utils/enums/EventStatus';
import BtnLock from '@views_admin/events/BtnLock';
import BtnDelete from '@views_admin/events/BtnDelete';
import moment from 'moment-timezone';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Name',
      value: 'name',
   },
   {
      label: 'Status',
      value: 'status',
   },
   {
      label: 'Start date',
      value: 'start_date',
   },
   {
      label: 'End date',
      value: 'end_date',
   },
];

const Events = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,

      status: '',
      fromRate: '',
      toRate: '',
      fromDate: '',
      toDate: '',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();

   const reloadData = useCallback((keepCurrentPage) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: keepCurrentPage === true ? prevFilter.page : 1,
      }));
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
               const now = moment().tz(getTimeZone());
               const startDate = moment(record.start_date).tz(getTimeZone());
               const endDate = moment(record.end_date).tz(getTimeZone());
               let isOccuring = false;
               if (!now.isBefore(startDate) && !now.isAfter(endDate)) {
                  isOccuring = true;
               }
               return (
                  <>
                     <Link
                        className="event-url"
                        to={`${constant.ROUTE_ADMIN_EVENTS}/${record.uid}`}
                        title={`${text} (in use)`}
                     >
                        {isOccuring && <spam className="inprogress" />}
                        <span className="description-col-content">{text}</span>
                     </Link>
                  </>
               );
            },
         },
         {
            title: 'Code',
            dataIndex: 'code',
         },
         {
            title: 'Coupon counts',
            dataIndex: 'coupon_amount',
            render: (text, record) => (
               <span>{text || <span className="nothing">0</span>}</span>
            ),
         },
         {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
               <span className={`event-stt ${text}`}>
                  {(() => {
                     return _capitalize(_lowerCase(text));
                  })()}
               </span>
            ),
         },
         {
            title: 'Start date',
            dataIndex: 'start_date',
            render: (text, record) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'End date',
            dataIndex: 'end_date',
            render: (text, record) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => {
               const now = moment().tz(getTimeZone());
               const startDate = moment(record.start_date).tz(getTimeZone());
               const endDate = moment(record.end_date).tz(getTimeZone());
               let isOccuring = false;
               if (!now.isBefore(startDate) && !now.isAfter(endDate)) {
                  isOccuring = true;
               }
               return (
                  <div className="admin-col-action">
                     <Tooltip placement="bottom" title="Edit" color="black">
                        <Link
                           className="button-icon action-normal"
                           to={`${constant.ROUTE_ADMIN_EVENTS}/${record.uid}`}
                        >
                           <IconEdit />
                        </Link>
                     </Tooltip>
                     {!isOccuring && (
                        <BtnLock
                           uid={record.uid}
                           isLocked={record.status === EventStatus.LOCKED}
                           onSuccess={reloadData}
                        />
                     )}
                     {!record.used && !isOccuring && (
                        <BtnDelete
                           uid={record.uid}
                           name={record.name}
                           onSuccess={reloadData}
                        />
                     )}
                  </div>
               );
            },
         },
      ];
   }, [reloadData]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadData = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await eventService.getEventsMatrix(
            requestBody,
            cancelToken.current?.token
         );
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   const handleFilter = useCallback((newFilter = {}) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
         page: 1,
      }));
   }, []);

   const filterSet = useMemo(() => {
      return {
         status: filter.status,
         fromDate: filter.fromDate,
         toDate: filter.toDate,
      };
   }, [filter]);

   return (
      <>
         <SEO title={'Events - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Events</label>
            </div>
            <div className="content-head__right">
               <Link
                  to={constant.ROUTE_ADMIN_NEW_EVENT}
                  className="outline-btn btn-add-new-item"
               >
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Event
               </Link>
            </div>
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search
                        placeholder="Search in events"
                        onSearch={(v) => setSearchText(v)}
                     />
                  </div>
                  <div className="admin-table__header--right">
                     <Filter filterSet={filterSet} onChange={handleFilter} />
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               {
                  <CommonTable
                     rowKey="uid"
                     unit="Events"
                     dataSource={data.content}
                     columns={columns}
                     page={filter.page}
                     pageSize={filter.pageSize}
                     totalRows={data.totalRows}
                     onChange={onTableChange}
                  />
               }
            </div>
         </div>
      </>
   );
});

export default Events;
