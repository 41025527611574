import { productApi } from '@apis/productApi';
import { message } from '@components/Message';
import IconLock from '@icons/IconLock';
import IconRestore from '@icons/IconRestore';
import IconUnlock from '@icons/IconUnlock';
import { eventService } from '@services/eventService';
import { Tooltip } from 'antd';
import React, { memo } from 'react';

const BtnLock = memo(({ uid, isLocked, onSuccess }) => {
   const doAction = async () => {
      if (uid) {
         message.loading(isLocked ? 'Unlocking...' : 'Locking...', 0);
         let responseData;
         if (isLocked) {
            responseData = await eventService.unlockEvent([uid]);
         } else {
            responseData = await eventService.lockEvent([uid]);
         }
         setTimeout(async () => {
            if (responseData.isSuccess) {
               message.success(
                  isLocked ? 'Unlock event successfully.' : 'Lock event successfully.'
               );
               if (onSuccess) {
                  onSuccess(true);
               }
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   };

   return (
      <Tooltip placement="bottom" title={isLocked ? 'Unlock' : 'Lock'} color="black">
         <button className={`button-icon`} onClick={doAction}>
            {isLocked ? <IconUnlock /> : <IconLock />}
         </button>
      </Tooltip>
   );
});

export default BtnLock;
