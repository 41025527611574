import { message } from '@components/Message';
import PopConfirm from '@components/PopConfirm';
import IconDelete2 from '@icons/IconDelete2';
import { eventService } from '@services/eventService';
import { Tooltip } from 'antd';
import React, { memo, useCallback, useState } from 'react';

const BtnDelete = memo(({ uid, onSuccess }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);

   const showModal = useCallback(() => {
      setIsModalVisible(true);
   }, []);

   const handleOk = useCallback(async () => {
      if (uid) {
         setIsModalVisible(false);
         message.loading('Deleting...', 0);
         const responseData = await eventService.deleteEvent([uid]);
         setTimeout(async () => {
            if (responseData.isSuccess) {
               if (onSuccess) {
                  onSuccess();
               }
               message.success('Delete event successfully.');
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }, [uid, onSuccess]);

   const onPopConfirmVisibleChange = useCallback((isOpen) => {
      setIsModalVisible(isOpen);
   }, []);

   return (
      <PopConfirm
         title="Are you sure to delete this event?"
         isOpen={isModalVisible}
         okText="Delete"
         zIndex={4039}
         onVisibleChange={onPopConfirmVisibleChange}
         onConfirm={handleOk}
      >
         <Tooltip placement="bottom" title="Delete" color="black">
            <button
               className={`button-icon action-delete ${isModalVisible ? 'active' : ''}`}
               onClick={showModal}
            >
               <IconDelete2 />
            </button>
         </Tooltip>
      </PopConfirm>
   );
});

export default BtnDelete;
