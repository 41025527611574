import React from 'react';

const IconLock = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M5.5 6V3.5C5.5 2.11929 6.61929 1 8 1C9.38071 1 10.5 2.11929 10.5 3.5V6H5.5ZM4.5 6V3.5C4.5 1.567 6.067 0 8 0C9.933 0 11.5 1.567 11.5 3.5V6H12C13.1046 6 14 6.89543 14 8V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V8C2 6.89543 2.89543 6 4 6H4.5ZM4.5 7H4C3.44772 7 3 7.44772 3 8V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7H11.5H10.5H5.5H4.5Z"
         fill="currentColor"
      />
   </svg>
);

export default IconLock;
