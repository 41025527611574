import React from 'react';

const IconUnlock = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.5 6V3.5C9.5 2.11929 10.6193 1 12 1C13.2095 1 14.2184 1.85888 14.45 3H15.4646C15.2219 1.30385 13.7632 0 12 0C10.067 0 8.5 1.567 8.5 3.5V6H3C1.89543 6 1 6.89543 1 8V14C1 15.1046 1.89543 16 3 16H11C12.1046 16 13 15.1046 13 14V8C13 6.89543 12.1046 6 11 6H9.5ZM3 7H11C11.5523 7 12 7.44772 12 8V14C12 14.5523 11.5523 15 11 15H3C2.44772 15 2 14.5523 2 14V8C2 7.44772 2.44772 7 3 7Z"
         fill="#FF8C4B"
      />
   </svg>
);

export default IconUnlock;