import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';

const getEventsMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_EVENT_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteEvent = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_EVENT,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const lockEvent = async (uids, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.LOCK_EVENT,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const unlockEvent = async (uids, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UNLOCK_EVENT,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const getEventDetails = async (uid, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_EVENT_DETAILS,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const createEvent = async (data, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_EVENT,
      data,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const updateEvent = async (uid, data, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_EVENT,
      data,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const checkCoupon = async (data, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CHECK_COUPON,
      data,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      ignoreFailCheck: true,
      errorHandler: adminService.handleError,
   });
};

export const eventService = {
   getEventsMatrix,
   deleteEvent,
   lockEvent,
   unlockEvent,
   getEventDetails,
   createEvent,
   updateEvent,
   checkCoupon,
};
